<template>
  <master-layout :pageTitle="Testing" :showBack="false">
    <h3>Notifiche</h3>
    <textarea style="width: 100%; height: 100px" v-model="textareadata"></textarea>
    <ion-button @click="
      openNotification({
        from: '992781005473',
        collapseKey: 'do_not_collapse',
        notification: { body: { title: 'Hello', body: 'Yellow' } },
      })
    ">Base</ion-button>
    <ion-button @click="
      openNotification({
        from: '992781005473',
        collapseKey: 'do_not_collapse',
        notification: { body: { titolo: 'Hello', testo: 'Yellow' } },
      })
    ">Base 2</ion-button>
    <ion-button @click="
      openNotification({
        from: '992781005473',
        collapseKey: 'do_not_collapse',
        notification: {
          body: {
            titolo: 'Hello',
            testo: 'Yellow<br>Yellow2',
            img_big: imgBig,
          },
        },
      })
    ">Base with image</ion-button>
    <ion-button @click="
      openNotification({
        from: '992781005473',
        collapseKey: 'do_not_collapse',
        notification: {
          body: { titolo: 'Hello', id_campagna: 88, tipo_azione: 'kpi' },
        },
      })
    ">Close to badge</ion-button>
    <ion-button @click="
      openNotification({
        from: '992781005473',
        collapseKey: 'do_not_collapse',
        notification: {
          body: {
            titolo: 'Complimenti Martina<br>hai concluso il task!',
            testo:
              'Fai la recensione del tuo supervisore <strong>Sara</strong> per ottenere <strong>20 punti extra</strong>',
            img_big: imgBig,
            img_small: testImg,
            img_small_label: 'Sara Verdi',
            action_label: 'Fai recensione',
          },
        },
      })
    ">2 Images and second image label</ion-button>
    <ion-button @click="
      openNotification({
        from: '992781005473',
        collapseKey: 'do_not_collapse',
        notification: {
          body: {
            titolo: 'Grande risultato, continua così!',
            testo:
              'Ciao Martina, Sara si congratula con te per aver scalato la classifica grazie al <strong>Badge ON Boarding</strong>',
            img_big: imgBig,
            img_small: testImg,
            img_small_2: testImg,
            action_label: 'Chiudi',
            customtype: 'title_sub',
          },
        },
      })
    ">1+2 Images, customtype title_sub</ion-button>
    <ion-button @click="
      openNotification({
        from: '992781005473',
        collapseKey: 'do_not_collapse',
        notification: {
          body: {
            titolo:
              'Dai una votazione a Sara<br>e spiega perché dal tuo punto di vista',
            img_big: imgBig,
            action_label: 'Invia',
            customtype: 'feedback',
          },
        },
      })
    ">customtype feedback</ion-button>

    <!-- <p>Azioni permesse:</p>
    <ul>
      <li>ranking - con campaign_id</li>
      <li>badge - con campaign_id</li>
      <li>classroom - con campaign_id</li>
      <li>ticket</li>
    </ul>

    <h3>Calendario</h3>
    <v-date-picker :attributes="attributes" :min-date="new Date()" :max-date="new Date()" :disabled-dates="{}" />
    {{ days }}

    <h3>Organigramma</h3>
    <OrgChart :data="orgData"></OrgChart> -->

    <button @click="provas">provas</button>
  </master-layout>
</template>

<script>
import { defineComponent } from "vue";

import "swiper/swiper-bundle.min.css";
import "swiper/components/pagination/pagination.scss";
import "@ionic/vue/css/ionic-swiper.css";
import NotificationService from "../common/mixins/Notification";
import * as d3 from "d3";
import { IonIcon, IonButton, IonButtons } from "@ionic/vue";

import { arrowForward } from "ionicons/icons";
import ApiService from "../common/service.api";
import genAxios from "../common/service.api";
import { translate } from "../common/helper.auth";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { IonContent, IonPage, IonicSwiper } from "@ionic/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import OrgChart from "@/components/OrgChart";

import axios from 'axios'

SwiperCore.use([IonicSwiper, Navigation, Pagination]);

export default {
  name: "Testing",
  components: {
    OrgChart,
    // IonIcon,
    // IonButton,

    IonButton,
    IonButtons,
    IonIcon,
    Swiper,
    SwiperSlide,
    IonContent,
    IonPage,
  },
  data() {
    return {
      textareadata: "",
      days: [
        { id: "2022-02-02", date: "2022-02-02T03:00:00.000Z" },
        { id: "2022-03-03", date: "2022-03-03T03:00:00.000Z" },
        { id: "2022-02-06", date: "2022-02-06T03:00:00.000Z" },
      ],
    };
  },
  computed: {
    dates() {
      return this.days.map((day) => day.date);
    },
    attributes() {
      return this.dates.map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
  },
  mixins: [NotificationService],
  created() {
    this.imgBig = this.whappyClientAsset + "img/logo.png";
    this.testImg = this.whappyClientAsset + "img/logo.png";
  },
  mounted() {
    this.orgData = [
      {
        name: "Ian Devling",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/cto.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CTO office",
        tags: "Ceo,tag1,manager,cto",
        isLoggedUser: "false",
        positionName: "Chief Executive Officer ",
        id: "O-6066",
        parentId: "",
        size: "",
      },
      {
        name: "Davolio Nancy",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CTO ",
        id: "O-6067",
        parentId: "O-6066",
        size: "",
      },
      {
        name: " Leverling Janet",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CTO ",
        id: "O-6068",
        parentId: "O-6066",
        size: "",
      },
      {
        name: " Leverling Janet",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CTO ",
        id: "O-6069",
        parentId: "O-6066",
        size: "",
      },
      {
        name: " Leverling Janet",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CTO ",
        id: "O-6070",
        parentId: "O-6066",
        size: "",
      },
      {
        name: " Leverling Janet",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CTO ",
        id: "O-6071",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "Fuller Andrew",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Linear Manager",
        id: "O-6072",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "Peacock Margaret",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CEO",
        id: "O-6073",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "Buchanan Steven",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Head of direction",
        id: "O-6074",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "Suyama Michael",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior sales manager",
        id: "O-6075",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "King Robert",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Sales Manager",
        id: "O-6076",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "West Adam",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CTO",
        id: "O-6077",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "Charlotte Cooper",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Trader",
        id: "O-6078",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "Yoshi Nagase",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Head of laboratory",
        id: "O-6079",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "Peter Wilson",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Head of channels",
        id: "O-6080",
        parentId: "O-6068",
        size: "",
      },
      {
        name: "Carlos Diaz",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Android Developer",
        id: "O-6081",
        parentId: "O-6068",
        size: "",
      },
      {
        name: "Sven Petersen",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Developer",
        id: "O-6082",
        parentId: "O-6068",
        size: "",
      },
      {
        name: "Peter Wilson",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Head of channels",
        id: "O-6083",
        parentId: "O-6069",
        size: "",
      },
      {
        name: "Carlos Diaz",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Android Developer",
        id: "O-6084",
        parentId: "O-6069",
        size: "",
      },
      {
        name: "Sven Petersen",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Developer",
        id: "O-6085",
        parentId: "O-6069",
        size: "",
      },
      {
        name: "Peter Wilson",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Head of channels",
        id: "O-6086",
        parentId: "O-6070",
        size: "",
      },
      {
        name: "Carlos Diaz",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Android Developer",
        id: "O-6087",
        parentId: "O-6070",
        size: "",
      },
      {
        name: "Sven Petersen",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Developer",
        id: "O-6088",
        parentId: "O-6070",
        size: "",
      },
      {
        name: " Leverling Janet",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CTO ",
        id: "O-6089",
        parentId: "O-6071",
        size: "",
      },
      {
        name: "Fuller Andrew",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Linear Manager",
        id: "O-6090",
        parentId: "O-6071",
        size: "",
      },
    ];
    // d3.csv(
    //     'https://raw.githubusercontent.com/bumbeishvili/sample-data/main/org.csv'
    // ).then(d => {
    //   console.log('fetched data');
    //   this.data = d;
    //   console.log(d);
    // });
  },
  methods: {
    provas() {
      genAxios.post('api.tinify.com', {
        Authorization: 'Basic D9RLnx75TwlZ4lnkf70wjl5tHC2sXNYS',
        source: {
          "url": "https://tinypng.com/images/panda-happy.png"
        }
      }).then(res => {
        console.log(res)
      })
    },
    onDayClick(day) {
      const idx = this.days.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id: day.id,
          date: day.date,
        });
      }
    },
    openNotification(payload) {
      this.textareadata = JSON.stringify(payload);
      this.openModal(payload);
    },
  },
  ionViewWillEnter() { },
};
</script>

<style lang="scss" scoped>
// .vc-day .vc-opacity-0 {
//   opacity: 0.5;
// }

// .vc-day .vc-pointer-events-none {
//   pointer-events: none;
// }

// .blocked {
//   position: relative;
// }
// .blocked:after {
//   content: "";
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   z-index: 1;
//   background: transparent;
// }
</style>
